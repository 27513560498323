<template>
  <div>
    <b-card>
      <b-form @submit.prevent="fetch(1)">
        <b-row class="d-flex justify-content-between">

          <b-col>
            <b-row>
              <b-col>
                <b-form-group class="mr-1" label="Vehicule">
                  <b-form-input v-model="filter.q" placeholder="Marque, Immatriculation, VIN, N° entrée ..." />
                </b-form-group>

              </b-col>
              <b-col>
                <b-form-group label="Statut">
                  <v-select label="libelle" v-model="filter.statut" :reduce="loc => loc.id" :options="$store.state.initialConfigModule.initialState.offre_status"></v-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Client">
                  <v-select @search="fetchClients" :reduce="loc => loc.id" v-model="filter.client" :options="clients" label="full_name"/>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Vendeur">
                  <v-select @search="fetchVendeurs" label="full_name" :reduce="loc => loc.id" v-model="filter.vendeur" :options="vendeurs"></v-select>
                </b-form-group>
              </b-col>
<!--              <b-col v-if="$can('voir-offres-concession', 'offres') || $can('voir-offres-global', 'offres')">-->
<!--                <b-form-group label="Concession">-->
<!--                <v-select :reduce="item => item.id" v-model="filter.concession_id" label="libelle" :options="$store.state.initialConfigModule.initialState.concessions" placeholder="Rechercher par concession"></v-select>-->
<!--                </b-form-group>-->
<!--              </b-col>-->
<!--              <b-col v-if="$can('voir-offres-concession', 'offres') || $can('voir-offres-site', 'offres') || $can('voir-offres-global', 'offres')">-->
<!--                <b-form-group label="Site">-->
<!--                <v-select :reduce="item => item.id" v-model="filter.site_id" label="libelle" :options="$store.state.initialConfigModule.initialState.sites" placeholder="Rechercher par site"></v-select>-->
<!--                </b-form-group>-->
<!--              </b-col>-->

            </b-row>
          </b-col>
          <b-col md="auto">
            <b-form-group label="-">
              <b-button
                  variant="primary"
                  type="submit"
              >
                Rechercher
              </b-button>
              <b-button v-if="$can('exporter-liste-offres', 'list-bdc')"  @click="exportHandler" :disabled="pendingExport" class="ml-1"  variant="primary">
                <b-spinner small v-if="pendingExport"></b-spinner>
                Exporter</b-button>

            </b-form-group>


          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-overlay :show="is_loading">
      <b-card no-body>
        <div class="m-2 d-flex justify-content-between">
          <div></div>
          <div class="align-self-center">
            {{pagination.total}} offres trouvées
          </div>

        </div>

        <b-table v-if="items"
                 ref="selectableTable"
                 :fields="fields"
                 :items="items"

                 responsive
                 class="mb-0"

        >
          <template #cell(offre)="data">

            <h5>{{data.item.ventes.length ? 'Bon de commande vente' : 'Offre de reprise'}} N°{{data.item.id}} </h5>
            <div>Client : <span class="font-weight-bold">{{data.item.client.full_name}}</span></div>
            <div>Vendeur : <span class="font-weight-bold">{{data.item.vendeur}}</span></div>
            <div>Date de création : <span class="font-weight-bold">{{data.item.created_at}}</span></div>
            <div>Concession <span class="font-weight-bold">{{data.item.concession.libelle}} : </span> / Site <span class="font-weight-bold">: {{data.item.site.libelle}}</span></div>
            
            <b-badge :variant="data.item.str_statut.variant">
              {{data.item.str_statut.libelle}}
            </b-badge>
          </template>
          <template #cell(vente)="data">
            <div v-if="index <= 1" v-for="(item, index) in data.item.ventes" class="vehicule-item">
              <vehicule-overview :with-image="false" :vehicule="item.vehicule" />

            </div>
            <span v-if="data.item.ventes.length > 2">... + {{data.item.ventes.length - 2}} véhicules </span>
          </template>
          <template #cell(reprises)="data">
            <div v-if="index <= 1" v-for="(item, index) in data.item.reprises" class="vehicule-item">
              <vehicule-overview :with-image="false" :vehicule="item.vehicule" />

            </div>
            <span v-if="data.item.reprises.length > 2">... + {{data.item.reprises.length - 2}} véhicules </span>
          </template>
          <template #cell(total)="data">
            {{(Math.abs(data.item.total).toLocaleString('en'))}} Dhs
          </template>
          <template #cell(actions)="data">
            <feather-icon

                icon="EyeIcon"
                size="16"
                class="mx-1 cursor-pointer  "
                @click="$router.push({ name: 'offre-view', params: { id: data.item.id }})"
            />
          </template>
        </b-table>


      </b-card>
    </b-overlay>

    <b-row v-if="!is_loading && items.length == 0">
      <b-col>
        <b-card class="text-center">
          Aucune offre trouvée
        </b-card>
      </b-col>
    </b-row>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-number
              last-number
              @change="fetch"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>

</template>

<script>

import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VehiculeOverview from "@/components/Vehicule/VehiculeOverview";
import axios from "@/libs/axios";


export default {
  name: "OffreListPage",
  components: {
    VehiculeOverview,
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pendingExport : false,
      fields: [ 'offre','vente', 'reprises',{key : 'total' , label : 'Montant'},{key: 'actions'}],
      items : [],
      pagination: {},
      current_page : 1,
      is_loading : false,
      clients : [],
      vendeurs : [],
      filter : {
        q : '',
        statut : null,
        client : null,
        vendeur : null
      }
    }
  },
  created() {
    this.fetch(1);  
  },
  methods: {
    exportHandler(){
      let _this = this;
      _this.pendingExport = true;
      
      axios.get(
          process.env.VUE_APP_API_URL + 'api/offres?export=1&q=' + _this.filter.q,
          {responseType: 'blob'} // !!!
      ).then((response) => {
        _this.pendingExport = false;
        window.open(URL.createObjectURL(response.data));
      });
    },
    fetchClients(search, loading) {
      if (!search) {
        return false;
      }
      let _this = this;
      this.$store.dispatch('clientsModule/fetchClients', {
        'q': search
      }).then(function (response) {
        _this.clients = response.data;
      })
    },
    fetchVendeurs(search, loading) {
      if (!search) {
        return false;
      }
      let _this = this;
      this.$store.dispatch('userModule/fetchUtilisateurs', {
        'q': search
      }).then(function (response) {
        _this.vendeurs = response.data;
      })
      console.log(search, loading)
    },
    fetch(page){
      let _this = this;
      _this.is_loading = true;
      this.$store.dispatch('offreModule/fetchAll',{
        page : page,
        ..._this.filter
      }).then(function (response) {
        _this.items = response.data;
        _this.pagination = response.meta;
        _this.is_loading = false;
      })
    }
  },
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
.vehicule-item{
  margin-bottom: 10px;
  &:last-child{
    margin-bottom: 0;
  }
}
</style>
